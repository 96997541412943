import React, { useCallback, useEffect, useState } from "react"; //
import { useLocation } from "react-router-dom";
import loaderlogo from "../Images/Logo/loader-logo.svg";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import iconphone from "../Images/Icons/icon-phone.svg";
import iconenvelope from "../Images/Icons/icon-envelope.svg";
import firstexchange from "../Images/Mobiles/first-exchange.png";
import Step1 from "../Images/Steps/Step-1.png";
import Step2 from "../Images/Steps/Step-2.png";
import Step3 from "../Images/Steps/Step-3.png";
import Footerlog from "../Images/Logo/footer-logo.svg";
import largestdexscreen from "../Images/Mobiles/largest-dex-screen.png";
import swapscreen from "../Images/Mobiles/swap-screen.png";
import unitscreen from "../Images/Mobiles/unit-screen.png";
import aboutscreen from "../Images/Mobiles/about-screen.png";
import hasslescreen1 from "../Images/Mobiles/hassle-screen-1.png";
import hasslescreen2 from "../Images/Mobiles/hassle-screen-2.png";
import scoin from "../Images/Icons/s-coin.svg";
import euteriumcoin from "../Images/Icons/etherium.svg";
import bitcoincoin from "../Images/Icons/Btc.svg";
import solanacoin from "../Images/Icons/solana.svg";
import iconswap from "../Images/Icons/icon-swap.svg";
import iconswapsetting from "../Images/Icons/icon-swap-setting.svg";
import searchicon from "../Images/Icons/search-icon.svg";
import toggleicon from "../Images/Icons/toggle-icon.png";
import IconRecieve from "../Images/Icons/icon-receive.png";
import IconCross from "../Images/Icons/icon-cross.png";
import IconBuy from "../Images/Icons/icon-buy.png";
import IconSwap from "../Images/Icons/icon-swap.png";
import IconBox from "../Images/Icons/circle-box.png";
import IconFb from "../Images/Icons/icon-facebook.png";
import IconInstagram from "../Images/Icons/icon-instagram.png";
import IconX from "../Images/Icons/icon-x.png";
import IconYoutube from "../Images/Icons/icon-youtube.png";
import { AppStoreButton, DownloadBtnList } from "../Components/ButtonList";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { ActiveSections, BASE_URL } from "../constant";
import CoinMarketCapWidget from "./CoinMarketCapWidget";
function Home({ setShowFooter, activeSection, setActiveSection }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validate = () => {
    const newErrors = {};

    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    } else if (formData.name.trim().length < 3) {
      newErrors.name = "Name must be at least 3 characters.";
    }

    // Validate email
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    // Validate phone number
    if (!formData.phoneNumber.trim()) {
      newErrors.phoneNumber = "Phone number is required.";
    } else if (!/^\+\d{10,15}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number format.";
    }

    // Validate message
    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
    } else if (formData.message.trim().length < 15) {
      newErrors.message = "Message must be at least 15 characters.";
    }

    return newErrors;
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: name === "phoneNumber" && !value.startsWith("+")
        ? `+${value.replace(/^\+/, "")}` // Add + if missing
        : value, // Keep value as is for other fields or valid phone number
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setIsSubmitting(true);

    const payload = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      message: formData.message,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/contactUs`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Message sent successfully!");
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    } catch (error) {
      // Log the error response for debugging
      if (error.response) {
        toast.error(`Failed to send the message: ${error.response.data.message || "Please try again later."}`);
      } else {
        toast.error("Failed to send the message. Please try again later.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDownloadClick = () => {
    // Set HasselFreeCrypto section as active
    setActiveSection(ActiveSections.HASSEL_FREE_CRYPTO); // Assuming index 1 corresponds to HasselFreeCrypto
  };

  // Hide the footer when the Login component mounts
  useEffect(() => {
    setShowFooter(false);

    return () => {
      // Re-enable the footer when the component unmounts
      setShowFooter(true);
    };
  }, [setShowFooter]);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const html = document.documentElement;
      const body = document.body;

      if (location.pathname === "/" && window.innerWidth > 992) {
        html.style.width = "100%";
        html.style.height = "100vh";
        html.style.overflow = "hidden";

        body.style.width = "100%";
        body.style.height = "100vh";
        body.style.overflow = "hidden";
      } else {
        html.style.width = "";
        html.style.height = "";
        html.style.overflow = "";

        body.style.width = "";
        body.style.height = "";
        body.style.overflow = "";
      }
    };

    // Run on mount and on every page load
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location]);

  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);

  // Web Animation

  // FirstDexAcoount Account Animation
  const [firstdexacoounttextMove, setFirstDexAcoountTextMove] = useState(false);
  const [steplistMove, setStepListMove] = useState(false);


  // Live Price Animation
  // const [livepricemarketMove, setLivePriceMarketMove] = useState(false);
  // const [livepricetxtMove, setLivePriceTxtMove] = useState(false);
  // const [livepriceiconMove, setLivePriceIconMove] = useState(false);

  // LargestDex Animation
  const [largestdeximgMove, setLargestDexImgMove] = useState(false);
  const [largestdextxtMove, setLargestDexTxtMove] = useState(false);
  const [largestdexiconMove, setLargestDexIconMove] = useState(false);

  // Swapcrypto Animation
  const [swapcryptoimgMove, setSwapCryptoImgMove] = useState(false);
  const [swapcryptotxtMove, setSwapCryptoTxtMove] = useState(false);
  const [swapcryptoiconMove, setSwapCryptoIconMove] = useState(false);

  // Unit Digital Animation
  const [unitdigitalimgMove, setUnitDigitalImgMove] = useState(false);
  const [unitdigitaltxtMove, setUnitDigitalTxtMove] = useState(false);
  const [unitdigitaliconMove, setUnitDigitalIconMove] = useState(false);

  // About Panel Animation
  const [aboutpnlimgMove, setAboutPnlImgMove] = useState(false);
  const [aboutpnltxtMove, setAboutPnlTxtMove] = useState(false);
  const [aboutpnliconMove, setAboutPnlIconMove] = useState(false);

  // HasselFree Animation
  const [hasselfreebgMove, setHasselFreeBgMove] = useState(false);
  const [hasselfreetxtMove, setHasselFreeTxtMove] = useState(false);
  const [hasselfreeimgMove, setHasselFreeImgMove] = useState(false);

  // GetInTouch Animation
  const [getintouchtxtMove, setGetInTouchTxtMove] = useState(false);
  const [getintouchformMove, setGetInTouchFormMove] = useState(false);
  const [getintouchiconMove, setGetInTouchIconMove] = useState(false);

  const [hash, setHash] = useState();

  // const [activeSection, setActiveSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false); // State to prevent rapid scrolling
  const sections = [
    "LandingPanel",
    // "LivePrice",
    "FirstDexAcoount",
    "LargestDex",
    "Swapcrypto",
    "Unitdigital",
    "Aboutpanel",
    "HasselFreeCrypto",
    "GetInTouch",
  ]; // Array to manage sections

  const handleScroll = useCallback(
    (event: React.WheelEvent) => {
      // Check if the screen width is at least 992px
      if (window.matchMedia("(min-width: 992px)").matches) {
        if (!isScrolling) {
          if (event.deltaY > 0) {
            // Scroll Down
            setActiveSection((prev) => Math.min(prev + 1, sections.length - 1));
          } else {
            // Scroll Up
            setActiveSection((prev) => Math.max(prev - 1, 0));
          }

          // Set isScrolling to true to prevent further scrolls
          setIsScrolling(true);

          // Wait for 2 seconds before allowing another scroll
          setTimeout(() => {
            setIsScrolling(false);
          }, 2000);
        }
      }
    },
    [isScrolling, sections.length]
  );

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [isScrolling]);

  // FirstDexAcoount Account
  useEffect(() => {
    if (activeSection === ActiveSections.FIRST_DEX_ACCOUNT) {
      // Delay adding 'move' class to bg-layer
      const firstdexacoounttextTimer = setTimeout(() => {
        setFirstDexAcoountTextMove(true);
      }, 200);
      const steplistTimer = setTimeout(() => {
        setStepListMove(true);
      }, 800);
      return () => {
        clearTimeout(firstdexacoounttextTimer);
        clearTimeout(steplistTimer);
      };
    }
    // if (activeSection === ActiveSections.LIVE_PRICE) {
    //   const livepricetxtTimer = setTimeout(() => {
    //     setLivePriceTxtMove(true);
    //   }, 100);
    //   const livepriceiconTimer = setTimeout(() => {
    //     setLivePriceIconMove(true);
    //   }, 1200);
    //   const livepricemarketTimer = setTimeout(() => {
    //     setLivePriceMarketMove(true);
    //   }, 1400);
    //   return () => {
    //     clearTimeout(livepricemarketTimer);
    //     clearTimeout(livepricetxtTimer);
    //     clearTimeout(livepriceiconTimer);
    //   };
    // }
    // else {
    //   // Reset states when leaving the section
    //   setLivePriceMarketMove(false);
    //   setLivePriceTxtMove(false);
    //   setLivePriceIconMove(false);
    // }
    else if (activeSection === ActiveSections.FIRST_DEX_ACCOUNT) {
      // Delay adding 'move' class to bg-layer
      const firstdexacoounttextTimer = setTimeout(() => {
        setFirstDexAcoountTextMove(true);
      }, 200);
      const steplistTimer = setTimeout(() => {
        setStepListMove(true);
      }, 800);
      return () => {
        clearTimeout(firstdexacoounttextTimer);
        clearTimeout(steplistTimer);
      };
    }
    // else {
    //   // Reset states when leaving the section
    //   setFirstDexAcoountTextMove(false);
    //   setStepListMove(false);
    // }
    else if (activeSection === ActiveSections.LARGEST_DEX) {
      // Delay adding 'move' class to bg-layer
      const largestdeximgTimer = setTimeout(() => {
        setLargestDexImgMove(true);
      }, 200);
      const largestdextxtTimer = setTimeout(() => {
        setLargestDexTxtMove(true);
      }, 200);
      const largestdexiconTimer = setTimeout(() => {
        setLargestDexIconMove(true);
      }, 700);
      return () => {
        clearTimeout(largestdeximgTimer);
        clearTimeout(largestdextxtTimer);
        clearTimeout(largestdexiconTimer);
      };
    }
    // else {
    //   // Reset states when leaving the section
    //   setLargestDexImgMove(false);
    //   setLargestDexTxtMove(false);
    //   setLargestDexIconMove(false);
    // }
    else if (activeSection === ActiveSections.SWAP_CRYPTO) {
      // Delay adding 'move' class to bg-layer
      const swapcryptoimgTimer = setTimeout(() => {
        setSwapCryptoImgMove(true);
      }, 200);
      const swapcryptotxtTimer = setTimeout(() => {
        setSwapCryptoTxtMove(true);
      }, 200);
      const swapcryptoiconTimer = setTimeout(() => {
        setSwapCryptoIconMove(true);
      }, 700);
      return () => {
        clearTimeout(swapcryptoimgTimer);
        clearTimeout(swapcryptotxtTimer);
        clearTimeout(swapcryptoiconTimer);
      };
    }
    //  else {
    //   // Reset states when leaving the section
    //   setSwapCryptoImgMove(false);
    //   setSwapCryptoTxtMove(false);
    //   setSwapCryptoIconMove(false);
    // }
    else if (activeSection === ActiveSections.UNIT_DIGITAL) {
      // Delay adding 'move' class to bg-layer
      const unitdigitalimgTimer = setTimeout(() => {
        setUnitDigitalImgMove(true);
      }, 200);
      const unitdigitaltxtTimer = setTimeout(() => {
        setUnitDigitalTxtMove(true);
      }, 200);
      const unitdigitaliconTimer = setTimeout(() => {
        setUnitDigitalIconMove(true);
      }, 700);
      return () => {
        clearTimeout(unitdigitalimgTimer);
        clearTimeout(unitdigitaltxtTimer);
        clearTimeout(unitdigitaliconTimer);
      };
    }
    // else {
    //   // Reset states when leaving the section
    //   setUnitDigitalImgMove(false);
    //   setUnitDigitalTxtMove(false);
    //   setUnitDigitalIconMove(false);
    // }
    else if (activeSection === ActiveSections.ABOUT_PANEL) {
      // Delay adding 'move' class to bg-layer
      const aboutpnlimgTimer = setTimeout(() => {
        setAboutPnlImgMove(true);
      }, 200);
      const aboutpnltxtTimer = setTimeout(() => {
        setAboutPnlTxtMove(true);
      }, 200);
      const aboutpnliconTimer = setTimeout(() => {
        setAboutPnlIconMove(true);
      }, 700);
      return () => {
        clearTimeout(aboutpnlimgTimer);
        clearTimeout(aboutpnltxtTimer);
        clearTimeout(aboutpnliconTimer);
      };
    }
    // else {
    //   // Reset states when leaving the section
    //   setAboutPnlImgMove(false);
    //   setAboutPnlTxtMove(false);
    //   setAboutPnlIconMove(false);
    // }
    else if (activeSection === ActiveSections.HASSEL_FREE_CRYPTO) {
      // Delay adding 'move' class to bg-layer
      const hasselfreebgTimer = setTimeout(() => {
        setHasselFreeBgMove(true);
      }, 200);
      const hasselfreetxtTimer = setTimeout(() => {
        setHasselFreeTxtMove(true);
      }, 700);
      const hasselfreeimgTimer = setTimeout(() => {
        setHasselFreeImgMove(true);
      }, 800);
      return () => {
        clearTimeout(hasselfreebgTimer);
        clearTimeout(hasselfreeimgTimer);
        clearTimeout(hasselfreetxtTimer);
      };
    }
    // else {
    //   // Reset states when leaving the section
    //   setHasselFreeBgMove(false);
    //   setHasselFreeImgMove(false);
    //   setHasselFreeTxtMove(false);
    // }
    else if (activeSection === ActiveSections.GET_IN_TOUCH) {
      // Delay adding 'move' class to bg-layer
      const getintouchtxtTimer = setTimeout(() => {
        setGetInTouchTxtMove(true);
      }, 200);
      const getintouchformTimer = setTimeout(() => {
        setGetInTouchFormMove(true);
      }, 700);
      const getintouchiconTimer = setTimeout(() => {
        setGetInTouchIconMove(true);
      }, 1500);
      return () => {
        clearTimeout(getintouchtxtTimer);
        clearTimeout(getintouchiconTimer);
        clearTimeout(getintouchformTimer);
      };
    }
    else {
      // Reset states when leaving the section
      setGetInTouchTxtMove(false);
      setGetInTouchFormMove(false);
      setGetInTouchIconMove(false);
    }
  }, [activeSection, window.location.pathname]);
  useEffect(() => {
    if (window.location.hash == "#live_prices") {
      setActiveSection(ActiveSections.LIVE_PRICE)
    }
  }, [window.location.hash]);

  return (
    <>
      {/* Loader Div */}
      <div className="loader-main">
        <Image src={loaderlogo} alt="Loader" />
      </div>
      {/* Loader Div */}

      <div className={`web-footer ${activeSection === ActiveSections.HASSEL_FREE_CRYPTO
        ? "white" : ""}`}>
        <p> <Link to="/PrivacyPolicy">Privacy Policy</Link> <Link to="/TermsConditions">Terms & Conditions</Link></p>
        <ul className="social-media-list">
          <li>
            <Link target="_blank" to="https://www.facebook.com/">
              <Image src={IconFb} alt="Facebook" />
            </Link>
          </li>
          <li>
            <Link target="_blank" to="https://www.instagram.com/">
              <Image src={IconInstagram} alt="Instagram" />
            </Link>
          </li>
          <li>
            <Link target="_blank" to="https://www.youtube.com/">
              <Image src={IconYoutube} alt="Youtbe" />
            </Link>
          </li>
          <li>
            <Link target="_blank" to="https://x.com/">
              <Image src={IconX} alt="X" />
            </Link>
          </li>
        </ul>
      </div>
      <div className="Liveprice-cntnr">
        <CoinMarketCapWidget activeSection={activeSection} />
      </div>

      {/* Main */}
      <div className="full-main">
        {/* Landing Home */}
        <section
          className={`full LandingPanel scroll-anime ${activeSection === 0 ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            <div className="landing-text">
              <h1>
                {" "}
                First Decentralized
                <br /> Exchange in India{" "}
              </h1>
              <p>
                <span>FirstDex</span> stands as a leading decentralized
                exchange, offering more than <span>300 tokens</span> across
                multiple blockchains to choose from providing unparalleled
                variety for traders and investors.
              </p>
              <p>
                Dive into a dynamic ecosystem where innovation meets diversity,
                empowering users to explore the crypto market like never before.
              </p>
            </div>
            <div className="bottom-pnl">
              <Image src={firstexchange} alt="first exchange" />
            </div>
          </div>
        </section>
        {/* Landing Home */}

        {/* Live Price */}
        {/* <section
          id="LivePrice"
          className={`full LivePrice scroll-anime ${activeSection === ActiveSections.LIVE_PRICE ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            <div className="text-center">
              <div className="live-price-text">
                <h2 className={`${livepricetxtMove ? "appear" : ""}`}>Today's Cryptocurrency<br /> Prices by FirstDex</h2>
                <span className={`${livepriceiconMove ? "purple-span crypto-span appear" : "purple-span crypto-span"}`}>
                  <Image src={scoin} alt="S Coin" />
                </span>
                <span className={`${livepriceiconMove ? "etherium-span crypto-span appear" : "etherium-span crypto-span"}`}>
                  <Image src={euteriumcoin} alt="Autherium" />
                </span>
                <span className={`${livepriceiconMove ? "btc-span appear crypto-span" : "btc-span crypto-span"}`}>
                  <Image src={bitcoincoin} alt="Bitcoin Coin" />
                </span>
                <span className={`${livepriceiconMove ? "solana-span appear crypto-span" : "solana-span crypto-span"}`}>
                  <Image src={solanacoin} alt="Solana Coin" />
                </span>
              </div>
              <p className={`${livepricetxtMove ? "appear" : ""}`}>Explore the future of digital finance with <span>FirstDEX!</span> Track today's cryptocurrency prices effortlessly—your gateway to secure, seamless, and real-time crypto updates</p>
            </div>
          </div>
        </section> */}
        {/* Live Price */}

        {/* First Dex Acoount */}
        <section
          id="FirstDexAcoount"
          className={`full FirstDexAcoount scroll-anime ${activeSection === ActiveSections.FIRST_DEX_ACCOUNT ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            {/* first-dex-text */}
            <div
              className={`${firstdexacoounttextMove
                ? "first-dex-text appear"
                : "first-dex-text"
                }`}
            >
              <h2>FirstDex Team</h2>
              <p>
                "FirstDex Labs was founded in 2022 and has been developing blockchain solutions for various sectors since its inception.
              </p>
              <p>
                FirstDex was launched with the mission to onboard as many people as possible to blockchain technology,
                empowering users with self-custody of their funds, transparency, and Decentralization. As we progress toward this goal,
                we plan to release tailored products designed to address specific needs across different sectors within the blockchain ecosystem."
              </p>
            </div>
            <div className="step-list-container">
              <ul
                className={`${steplistMove ? "step-list transform" : "step-list"
                  }`}
              >
                <li>
                  <Image src={Step1} alt="Step 1" />
                </li>
                <li>
                  <Image src={Step2} alt="Step 2" />
                </li>
                <li>
                  <Image src={Step3} alt="Step 3" />
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* First Dex Acoount */}

        {/* LargestDex */}
        <section
          className={`full LargestDex scroll-anime ${activeSection === ActiveSections.LARGEST_DEX ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            <div className="crypto-text-pnl">
              <div
                className={`${largestdeximgMove ? "img-pnl trans-left" : "img-pnl"
                  }`}
              >
                <Image src={largestdexscreen} alt="Largest Dex Screen" />
                <span
                  className={`${largestdexiconMove ? "purple-span appear" : "purple-span"
                    }`}
                >
                  <Image src={scoin} alt="S Coin" />
                </span>
                <span
                  className={`${largestdexiconMove
                    ? "etherium-span appear"
                    : "etherium-span"
                    }`}
                >
                  <Image src={euteriumcoin} alt="Autherium" />
                </span>
                <span
                  className={`${largestdexiconMove ? "btc-span appear" : "btc-span"
                    }`}
                >
                  <Image src={bitcoincoin} alt="Bitcoin Coin" />
                </span>
                <span
                  className={`${largestdexiconMove ? "solana-span appear" : "solana-span"
                    }`}
                >
                  <Image src={solanacoin} alt="Solana Coin" />
                </span>
              </div>
              <div
                className={`${largestdextxtMove ? "txt-pnl trans-right" : "txt-pnl"
                  }`}
              >
                <h3>
                  <Image src={IconBox} alt="Icon Box" /> Largest Dex with more
                  than 300 tokens to choose from to buy{" "}
                </h3>
                <p>
                  Access all major tokens from leading blockchains like
                  Ethereum, Solana, and Binance Smart Chain (BNB) for seamless
                  buying and selling within seconds.
                </p>
                <DownloadBtnList onClick={handleDownloadClick} />
              </div>
            </div>
          </div>
        </section>
        {/* LargestDex */}

        {/* Swapcrypto */}
        <section
          className={`full Swapcrypto scroll-anime ${activeSection === ActiveSections.SWAP_CRYPTO ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            <div className="crypto-text-pnl">
              <div
                className={`${swapcryptoimgMove ? "img-pnl trans-left" : "img-pnl"
                  }`}
              >
                <Image src={swapscreen} alt="Largest Dex Screen" />
                <span
                  className={`${swapcryptoiconMove ? "blue-span appear" : "blue-span"
                    }`}
                >
                  <Image src={iconswap} alt="Icon Swap" />
                </span>
                <span
                  className={`${swapcryptoiconMove ? "white-span appear" : "white-span"
                    }`}
                >
                  <Image src={iconswapsetting} alt="Icon Swap Setting" />
                </span>
                <span
                  className={`${swapcryptoiconMove
                    ? "etherium-span appear"
                    : "etherium-span"
                    }`}
                >
                  <Image src={euteriumcoin} alt="Etherium Coin" />
                </span>
              </div>
              <div
                className={`${swapcryptotxtMove ? "txt-pnl trans-right" : "txt-pnl"
                  }`}
              >
                <h3>
                  <Image src={IconBox} alt="Icon Box" /> Swap Crypto
                  Effortlessly{" "}
                </h3>
                <p>
                  Swap tokens across multiple blockchains—all in a secure, fast,
                  and simple process.
                </p>
                <DownloadBtnList onClick={handleDownloadClick} />
              </div>
            </div>
          </div>
        </section>
        {/* Swapcrypto */}

        {/* Unit digita */}
        <section
          className={`full Unitdigital scroll-anime ${activeSection === ActiveSections.UNIT_DIGITAL ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            <div className="crypto-text-pnl">
              <div
                className={`${unitdigitalimgMove ? "img-pnl trans-left" : "img-pnl"
                  }`}
              >
                <Image src={unitscreen} alt="Unit Screen" />
                <span
                  className={`${unitdigitaliconMove ? "purple-span appear" : "purple-span"
                    }`}
                >
                  <Image src={scoin} alt="S Coin" />
                </span>
                <span
                  className={`${unitdigitaliconMove ? "solana-span appear" : "solana-span"
                    }`}
                >
                  <Image src={solanacoin} alt="Solana Coin" />
                </span>
                <span
                  className={`${unitdigitaliconMove ? "white-span appear" : "white-span"
                    }`}
                >
                  <Image src={searchicon} alt="search Coin" />
                </span>
                <span
                  className={`${unitdigitaliconMove
                    ? "trans-span toggle-span appear"
                    : "trans-span toggle-span"
                    }`}
                >
                  <Image src={toggleicon} alt="Toggle Coin" />
                </span>
                <span
                  className={`${unitdigitaliconMove ? "text-span appear" : "text-span"
                    }`}
                >
                  All Blockchain
                </span>
              </div>
              <div
                className={`${unitdigitaltxtMove ? "txt-pnl trans-right" : "txt-pnl"
                  }`}
              >
                <h3>
                  <Image src={IconBox} alt="Icon Box" /> Unite your digital
                  world
                </h3>
                <p>
                  Manage assets across multiple blockchains in one intuitive
                  platform, keeping you in complete control.
                </p>
                <DownloadBtnList onClick={handleDownloadClick} />
              </div>
            </div>
          </div>
        </section>
        {/* Unit digita */}

        {/* Aboutpanel */}
        <section
          className={`full Aboutpanel scroll-anime ${activeSection === ActiveSections.ABOUT_PANEL ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            <div className="crypto-text-pnl">
              <div
                className={`${aboutpnlimgMove ? "img-pnl trans-left" : "img-pnl"
                  }`}
              >
                <Image src={aboutscreen} alt="About Screen" />
                <span
                  className={`${aboutpnliconMove ? "white-span appear" : "white-span"
                    }`}
                >
                  <Image src={IconRecieve} alt="Icon Recieve" /> Receive
                </span>
                <span
                  className={`${aboutpnliconMove ? "white-span appear" : "white-span"
                    }`}
                >
                  <Image src={IconCross} alt="Icon Cross" /> Cross
                </span>
                <span
                  className={`${aboutpnliconMove ? "white-span appear" : "white-span"
                    }`}
                >
                  <Image src={IconBuy} alt="Icon Buy" /> Buy
                </span>
                <span
                  className={`${aboutpnliconMove ? "white-span appear" : "white-span"
                    }`}
                >
                  <Image src={IconSwap} alt="Icon Swap" /> Swap
                </span>
              </div>
              <div
                className={`${aboutpnltxtMove ? "txt-pnl trans-right" : "txt-pnl"
                  }`}
              >
                <h3>
                  <Image src={IconBox} alt="Icon Box" /> FIU Registered
                </h3>
                <p>
                  Registered with Financial Intelligence Unit and fully
                  complaint with KYC/AML law
                </p>
                <h3>
                  <Image src={IconBox} alt="Icon Box" /> Self Custody
                </h3>
                <p>
                  Every transaction is executed on blockchain, eliminating all
                  the problems associated with buying from centralized
                  authorities like transparency, having no custody of funds etc.
                  Users can go to Solscan and enter their wallet address and
                  check all the tokens they hold and respective balances
                  providing complete transparency.
                </p>
                <h3>
                  <Image src={IconBox} alt="Icon Box" /> Top Tier Security
                </h3>
                <p>
                  Built on the robust Solana blockchain, it ensures
                  lightning-fast transactions and minimal fees, making crypto
                  trading seamless. From major cryptocurrencies to emerging
                  tokens, FirstDex caters to all trading needs while maintaining
                  top-tier security
                </p>
                <DownloadBtnList onClick={handleDownloadClick} />
              </div>
            </div>
          </div>
        </section>
        {/* Aboutpanel */}

        {/* Hassel Free Crypto */}
        <section
          id="HasselFreeCrypto"
          className={`full HasselFreeCrypto scroll-anime ${activeSection === ActiveSections.HASSEL_FREE_CRYPTO ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            <div
              className={`${hasselfreebgMove ? "bg-layer appear" : "bg-layer"}`}
            />
            <div className="hasselcrypto-text">
              <div
                className={`${hasselfreetxtMove ? "txt-pnl trans-left" : "txt-pnl"
                  }`}
              >
                <h2>
                  Start your hassle-free crypto journey with{" "}
                  <span>FirstDex</span> today!
                </h2>
                <p>
                  Experience secure transactions, seamless swaps, and compliant
                  solutions—all in one powerful wallet.
                </p>
                <AppStoreButton />
              </div>
              <div
                className={`${hasselfreeimgMove ? "img-pnl trans-right" : "img-pnl"
                  }`}
              >
                <Image src={hasslescreen1} alt="Hassle Screen" />
                <Image src={hasslescreen2} alt="Hassle Screen" />
              </div>
            </div>
          </div>
        </section>
        {/* Hassel Free Crypto */}

        {/* Get In Touch */}
        <section id="GetInTouch"
          className={`full GetInTouch scroll-anime ${activeSection === ActiveSections.GET_IN_TOUCH ? "active" : ""
            }`}
        >
          <div className="full-main-inner">
            <div
              className={`${getintouchtxtMove
                ? "get-in-touch-text trans-down"
                : "get-in-touch-text"
                }`}
            >
              <span
                className={`${getintouchiconMove ? "icon-phone appear" : "icon-phone"
                  }`}
              >
                <Image src={iconphone} alt="Icon Phone" />
              </span>
              <span
                className={`${getintouchiconMove ? "icon-envelope appear" : "icon-envelope"
                  }`}
              >
                <Image src={iconenvelope} alt="Icon Phone" />
              </span>
              <h1>Get In Touch</h1>
              <p>Let's Unlock the Future of Crypto Together!</p>
            </div>
            <Form
              onSubmit={handleSubmit}
              className={`${getintouchformMove ? "appear" : ""}`}
            >
              <Form.Group className="form-group" controlId="name">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="John Doe"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group" controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="+00 000 00000"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Type..."
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                className="submit-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send"}
              </Button>
            </Form>
          </div>
        </section>
        {/* Get In Touch */}

        {/* Footer */}
        <Container className="footer" fluid>
          <Row>
            <Container>
              <Row>
                <Col xl="12" lg="12" md="12">
                  <Image className="footer-logo" src={Footerlog} alt="Logo" />
                  <ul className="footer-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <a href="/#FirstDexAcoount">About</a>
                    </li>
                    <li>
                      <a href="/#GetInTouch">Tutorial Videos </a>
                    </li>
                    <li>
                      <Link to="/Faq">FAQs</Link>
                    </li>
                  </ul>
                  <ul className="social-media-list">
                    <li>
                      <Link target="_blank" to="https://www.facebook.com/">
                        <Image src={IconFb} alt="Facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to="https://www.instagram.com/">
                        <Image src={IconInstagram} alt="Instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to="https://www.youtube.com/">
                        <Image src={IconYoutube} alt="Youtbe" />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to="https://x.com/">
                        <Image src={IconX} alt="X" />
                      </Link>
                    </li>
                  </ul>
                  <p>©Copyright 2025 FirstDex</p>
                  <ul className="Privacy-list">
                    <li>
                      <Link to="/PrivacyPolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/TermsConditions">Terms & Conditions</Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Footer */}
      </div>
      {/* Main */}
    </>
  );
}
export default Home;
