

// import React, { useEffect } from "react";
// import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Logo from "../Images/Logo/logo.svg";
// import { ActiveSections } from "../constant";
// // import { Link } from "react-router-dom";

// function NavBar({ activeSection, setActiveSection }) {
//   const location = useLocation();
//   const pathname = location.pathname;
//   const navigate = useNavigate();
//   const getActiveLinkClass = (path) => (pathname === path ? "active" : "");

//   // Determine if we're on specific pages
//   const isPrivacyOrTerms = pathname === "/PrivacyPolicy" || pathname === "/TermsConditions" || pathname === "/Faq" || pathname === "/LivePrice";


//   const handleFaqClick = (event) => {
//     event.preventDefault();
//     window.location.href = "/Faq"; // Reload the page
//   };

//   const DownlodClick = () => {
//     setActiveSection(ActiveSections.HASSEL_FREE_CRYPTO);
//     window.location.href = "/#HasselFreeCrypto"; // Force reload and navigate
//   };

//   // Scroll to the section on page load
//   useEffect(() => {
//     if (location.hash) {
//       const element = document.querySelector(location.hash);
//       if (location.hash === "#FirstDexAcoount")
//         setActiveSection(ActiveSections.FIRST_DEX_ACCOUNT);
//       else if (location.hash === "#GetInTouch")
//         setActiveSection(ActiveSections.GET_IN_TOUCH);
//       if (element) {
//         element.scrollIntoView({ behavior: "smooth" });
//       }
//     }
//   }, [location.hash]);

//   return (
//     <Navbar
//       expand="lg"
//       className={`custom-navbar ${isPrivacyOrTerms ? "navbar-special" : ""}`}
//     >
//       <Container fluid>
//         {/* Logo */}
//         <Navbar.Brand href="/" className="navbar-logo">
//           <Image src={Logo} alt="Company Logo" />
//         </Navbar.Brand>

//         {/* Toggle Button for Mobile */}
//         <div className="btns-cntnr">
//           <a href="/#HasselFreeCrypto" className="reg-btn fill">
//             Download
//           </a>
//         </div>
//         <Navbar.Collapse id="navbarScroll" className="navbar-links">
//           <Nav>
//              {/* Home Link */}
//             {/* <Nav.Link href="/" className={`${(useLocation().pathname) == '/' ? 'blink' : ''}`}>
//               Home
//             </Nav.Link> */}

//             {/* Live Price  */}
//             {/* <Nav.Link href="/#live_prices" className={`live-btn ${activeSection === ActiveSections.LIVE_PRICE ? "blink" : ""}`}>
//               Live Prices
//             </Nav.Link> */}

//             {/* About Link */}
//             <Nav.Link
//               href="/#FirstDexAcoount"
//               className={`about-btn ${
//                 activeSection === ActiveSections.FIRST_DEX_ACCOUNT
//                   ? "blink"
//                   : ""
//               }`}
//               // onClick={handleAboutClick}
//             >
//               About
//             </Nav.Link>

//             {/* Tutorial Videos */}
//             <Nav.Link
//               href="/#GetInTouch"
//               className={`help-btn ${
//                 activeSection === ActiveSections.GET_IN_TOUCH ? "blink" : ""
//               }`}
//               // onClick={handleHelpClick}
//             >
//               Tutorial Videos
//             </Nav.Link>

//             {/* FAQs */}
//             <Nav.Link
//               href="/Faq"
//               className={`${useLocation().pathname === "/Faq" ? "blink" : ""}`}
//               onClick={handleFaqClick}
//             >
//               FAQs
//             </Nav.Link>
//              {/* <Nav.Link  href="/Faq" className={`${useLocation().pathname === '/Faq' ? 'blink' : ''}`} onClick={handleFaqClick} >
//               FAQs
//             </Nav.Link> */}

//             {/* <Nav.Link  href="/PrivacyPolicy" className={`${(useLocation().pathname) == '/PrivacyPolicy' ? 'blink' : ''}`}>
//               Privacy Policy
//             </Nav.Link>

//             <Nav.Link href="/TermsConditions" className={`${(useLocation().pathname) == '/TermsConditions' ? 'blink' : ''}`}>
//               Terms & Conditions
//             </Nav.Link> */}
//           </Nav>
//         </Navbar.Collapse>

//         {/* Right: Buttons */}
//         <ul className="btn-list">
//           <li>
//             <Button
//               className={`reg-btn ${
//                 activeSection === ActiveSections.HASSEL_FREE_CRYPTO ? "" : ""
//               }`}
//               onClick={DownlodClick}
//             >
//               Download
//             </Button>
//           </li>
//         </ul>
//       </Container>
//     </Navbar>
//   );
// }

// export default NavBar;


// import React, { useEffect, useState } from "react";
// import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
// import { useLocation, useNavigate } from "react-router-dom";
// import Logo from "../Images/Logo/logo.svg";
// import { ActiveSections } from "../constant";
// import TutorialVideosModal from "./TutorialVideosModal"; // Import modal component

// function NavBar({ activeSection, setActiveSection }) {
//   const location = useLocation();
//   const pathname = location.pathname;
//   const navigate = useNavigate();
//   const [showVideos, setShowVideos] = useState(false); // State for modal

//   const getActiveLinkClass = (path) => (pathname === path ? "active" : "");

//   const isPrivacyOrTerms =
//     pathname === "/PrivacyPolicy" ||
//     pathname === "/TermsConditions" ||
//     pathname === "/Faq" ||
//     pathname === "/LivePrice";

//   const handleFaqClick = (event) => {
//     event.preventDefault();
//     window.location.href = "/Faq";
//   };

//   const DownlodClick = () => {
//     setActiveSection(ActiveSections.HASSEL_FREE_CRYPTO);
//     window.location.href = "/#HasselFreeCrypto";
//   };

//   useEffect(() => {
//     if (location.hash) {
//       const element = document.querySelector(location.hash);
//       if (location.hash === "#FirstDexAcoount")
//         setActiveSection(ActiveSections.FIRST_DEX_ACCOUNT);
//       else if (location.hash === "#GetInTouch")
//         setActiveSection(ActiveSections.GET_IN_TOUCH);
//       if (element) {
//         element.scrollIntoView({ behavior: "smooth" });
//       }
//     }
//   }, [location.hash]);

//   return (
//     <>
//       <Navbar expand="lg" className={`custom-navbar ${isPrivacyOrTerms ? "navbar-special" : ""}`}>
//         <Container fluid>
//           {/* Logo */}
//           <Navbar.Brand href="/" className="navbar-logo">
//             <Image src={Logo} alt="Company Logo" />
//           </Navbar.Brand>

//           {/* Download Button */}
//           <div className="btns-cntnr">
//             <a href="/#HasselFreeCrypto" className="reg-btn fill">
//               Download
//             </a>
//           </div>

//           <Navbar.Collapse id="navbarScroll" className="navbar-links">
//             <Nav>
//               {/* About Link */}
//               <Nav.Link
//                 href="/#FirstDexAcoount"
//                 className={`about-btn ${activeSection === ActiveSections.FIRST_DEX_ACCOUNT ? "blink" : ""}`}
//               >
//                 About
//               </Nav.Link>

//               {/* Tutorial Videos - Opens Modal */}
//               <Nav.Link
//                 className={`help-btn ${activeSection === ActiveSections.GET_IN_TOUCH ? "blink" : ""}`}
//                 onClick={() => setShowVideos(true)} // Open modal
//               >
//                 Tutorial Videos
//               </Nav.Link>

//               {/* FAQs */}
//               <Nav.Link
//                 href="/Faq"
//                 className={`${useLocation().pathname === "/Faq" ? "blink" : ""}`}
//                 onClick={handleFaqClick}
//               >
//                 FAQs
//               </Nav.Link>
//             </Nav>
//           </Navbar.Collapse>

//           {/* Right: Buttons */}
//           <ul className="btn-list">
//             <li>
//               <Button className="reg-btn" onClick={DownlodClick}>
//                 Download
//               </Button>
//             </li>
//           </ul>
//         </Container>
//       </Navbar>

//       {/* Modal Component for Videos */}
//       <TutorialVideosModal show={showVideos} handleClose={() => setShowVideos(false)} />
//     </>
//   );
// }

// export default NavBar;



import React, { useEffect, useState } from "react"; 
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../Images/Logo/logo.svg";
import { ActiveSections } from "../constant";
import TutorialVideosModal from "./TutorialVideosModal"; // Import modal component

function NavBar({ activeSection, setActiveSection }) {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [showVideos, setShowVideos] = useState(false); // State for modal

  const getActiveLinkClass = (path) => (pathname === path ? "active" : "");

  const isPrivacyOrTerms =
    pathname === "/PrivacyPolicy" ||
    pathname === "/TermsConditions" ||
    pathname === "/Faq" ||
    pathname === "/LivePrice";

  const handleFaqClick = (event) => {
    event.preventDefault();
    navigate("/Faq");
  };

  const DownlodClick = () => {
    setActiveSection(ActiveSections.HASSEL_FREE_CRYPTO);
    navigate("/#HasselFreeCrypto");
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (location.hash === "#FirstDexAcoount")
        setActiveSection(ActiveSections.FIRST_DEX_ACCOUNT);
      else if (location.hash === "#GetInTouch")
        setActiveSection(ActiveSections.GET_IN_TOUCH);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash, setActiveSection]);

  return (
    <>
      <Navbar expand="lg" className={`custom-navbar ${isPrivacyOrTerms ? "navbar-special" : ""}`}>
        <Container fluid>
          {/* Logo */}
          <Navbar.Brand href="/" className="navbar-logo">
            <Image src={Logo} alt="Company Logo" />
          </Navbar.Brand>

          {/* Download Button */}
          <div className="btns-cntnr">
            <a href="/#HasselFreeCrypto" className="reg-btn fill">
              Download
            </a>
          </div>

          <Navbar.Collapse id="navbarScroll" className="navbar-links">
            <Nav>
              {/* About Link */}
              <Nav.Link
                href="/#FirstDexAcoount"
                className={`about-btn ${activeSection === ActiveSections.FIRST_DEX_ACCOUNT ? "blink" : ""}`}
              >
                About
              </Nav.Link>

              {/* Tutorial Videos - Opens Modal */}
              <Nav.Link
                className={`help-btn ${activeSection === ActiveSections.GET_IN_TOUCH ? "blink" : ""}`}
                onClick={() => setShowVideos(true)} // Open modal
              >
                Tutorial Videos
              </Nav.Link>

              {/* FAQs */}
              <Nav.Link
                href="/Faq"
                className={`${pathname === "/Faq" ? "blink" : ""}`}
                onClick={handleFaqClick}
              >
                FAQs
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          {/* Right: Buttons */}
          <ul className="btn-list">
            <li>
              <Button className="reg-btn" onClick={DownlodClick}>
                Download
              </Button>
            </li>
          </ul>
        </Container>
      </Navbar>

      {/* Modal Component for Videos */}
      <TutorialVideosModal show={showVideos} handleClose={() => setShowVideos(false)} />
    </>
  );
}

export default NavBar;
