// import React from "react";

// const Tutorials = () => {
//   return (
//     <div className="tutorials-container">
//       <h1>Tutorial Videos</h1>

//       <div className="video-wrapper">
//         <h3>Swapping on SOL</h3>
//         <video width="640" height="360" controls>
//           <source src="/videos/Swapping on SOL.mp4" type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       </div>

//       <div className="video-wrapper">
//         <h3>Swapping on ETH</h3>
//         <video width="640" height="360" controls>
//           <source src="/videos/Swapping on ETH.mp4" type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       </div>
//     </div>
//   );
// };

// export default Tutorials;


import React from "react";

const Tutorials = () => {
  return (
    <div className="tutorials-container">
      <h1>Tutorial Videos</h1>

      <div className="video-wrapper">
        <h3>Swapping on SOL</h3>
        <video width="640" height="360" controls>
          <source src="/videos/Swapping on SOL.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Tutorials;

