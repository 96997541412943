// import React from "react";
// import { Modal, Button } from "react-bootstrap";

// const TutorialVideosModal = ({ show, handleClose }) => {
//   return (
//     <Modal show={show} onHide={handleClose} centered>
//       <Modal.Header closeButton>
//         <Modal.Title>Watch Our Tutorials</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
//           <div style={{ textAlign: "center" }}>
//             <h5>Swapping on Solana</h5>
//             <video width="50" height="70" controls>
//               <source src="/videos/Swapping on SOL.mp4" type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>

//           <div style={{ textAlign: "center" }}>
//             <h5>Swapping on Ethereum</h5>
//             <video width="50" height="10" controls>
//               <source src="/videos/Swapping on ETH.mp4" type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default TutorialVideosModal;



// This script is for both


// import React from "react";
// import { Modal, Button } from "react-bootstrap";

// const TutorialVideosModal = ({ show, handleClose }) => {
//   return (
//     <Modal show={show} onHide={handleClose} centered>
//       <Modal.Header closeButton>
//         <Modal.Title>Watch Our Tutorials</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
//           <div style={{ textAlign: "center" }}>
//             <h5>Swapping on Solana</h5>
//             <video style={{ width: "150px", height: "170px" }} controls>
//               <source src="/videos/Swapping on SOL.mp4" type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>

//           <div style={{ textAlign: "center" }}>
//             <h5>Swapping on Ethereum</h5>
//             <video style={{ width: "150px", height: "170px" }} controls>
//               <source src="/videos/Swapping on ETH.mp4" type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default TutorialVideosModal;


// only for sol

import React from "react";
import { Modal, Button } from "react-bootstrap";

const TutorialVideosModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Watch Our Tutorials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ textAlign: "center" }}>
            <h5>Swapping on Solana</h5>
            <video style={{ width: "150px", height: "170px" }} controls>
              <source src="/videos/Swapping on SOL.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TutorialVideosModal;






